.create-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.363);
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-container .modal {
  background-color: #fff;
  width: 60vw;
  height: 70vh;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.create-container .modal header {
  padding: 1rem;
  background-color: beige;
  display: flex;
  align-items: start;
}

.create-container .modal header span {
  margin-left: 2px;
  padding: 0.2rem;
  font-size: 0.7rem;
  border-radius: 10%;
  opacity: 0.6;
  background-color: rgba(128, 128, 128, 0.253);
}

.create-container .modal h2 span {
  font-size: 0.7rem;
  padding: 2px;
  border-radius: 50%;
}

.create-container .modal button {
  padding: 0.7rem;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
  margin-bottom: auto;
  width: max-content;
  border: 1px solid gray;
  background-color: transparent;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.create-container .modal button:hover {
  transform: scale(1.1);
}

.create-container .modal .cancel {
  margin: 1rem 1rem 1rem 45rem;
}

.create-container .modal .modal-body {
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex: 1;
}

.create-container .modal .modal-body input {
  padding: 0.8rem;
  border: 1px solid #80808048;
  outline: none;
  width: 100%;
  height: 3rem;
  border-radius: 5px;
}

.create-container .modal .modal-body .segment-type,
.create-container .modal .modal-body .segment-category {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.create-container .modal .modal-body .segment-type label,
.create-container .modal .modal-body .segment-category label {
  padding: 0.5rem;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.281);
  width: 90%;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.create-container .modal .modal-body .segment-type label:hover,
.create-container .modal .modal-body .segment-category label:hover {
  transform: scale(1.1);
}

.create-container .modal .modal-body .segment-type .active,
.create-container .modal .modal-body .segment-category .active {
  background-color: #fac710;
}

.create-container .modal .modal-body .target-market,
.create-container .modal .modal-body .final {
  width: 100%;
}

.create-container .modal .modal-body .target-market .top,
.create-container .modal .modal-body .final .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}

.create-container .modal .modal-body .target-market select,
.create-container .modal .modal-body .final select {
  width: 100%;
  border: 1px solid #80808048;
  outline: none;
  margin-bottom: 1rem;
}

.create-container .modal .modal-body .tool-tip {
  position: relative;
  width: max-content;
}

.create-container .modal .modal-body .tool-tip:hover span {
  display: inline-flex;
}

.create-container .modal .modal-body .tool-tip button {
  background-color: #fac710;
  border: none;
}

.create-container .modal .modal-body .tool-tip span {
  display: none;
  position: absolute;
  width: 10rem;
  background-color: rgba(0, 0, 0, 0.116);
  top: 0.5rem;
  padding: 0.4em;
  font-size: 0.8rem;
  margin-left: 0.4rem;
  border-radius: 0.4rem;
}

.create-container .modal .modal-body .modal-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.create-container .modal .modal-body .modal-actions button {
  padding: 0.7rem;
  border: none;
  cursor: pointer;
  border-radius: 0.3rem;
  border: 1px solid gray;
  background-color: black;
  color: white;
}

.create-container .modal .modal-body .modal-actions button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: gray !important;
  transform: none;
}

.create-container .modal .modal-body .modal-actions button:last-child {
  background-color: #fac710;
  border: none;
  color: black;
}
