footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #111111;
    height: 100px;
    color: white;
    font-style: italic;
    font-size: 2rem;
    bottom: 0;
  }
  