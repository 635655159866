.table {
  width: 100%;
  padding: 1rem;
  /* max-height: 30rem; */
  /* overflow-y: scroll; */
}

.table .table-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table .table-filter label {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.8rem;
}

.table .table-filter button {
  padding: 0.5rem;
  background-color: rgb(27, 26, 26);
  color: white;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.table .table-filter button:hover {
  opacity: 1.2;
}

.table .table-filter .filter {
  position: relative;
}

.table .table-filter .filter .filter-modal {
  padding: 0.4rem;
  background-color: whitesmoke;
  border-radius: 10px;
  width: 14rem;
  position: absolute;
  z-index: 10;
}

.table .table-filter .filter .filter-modal label {
  padding: 0.5rem;
  cursor: pointer;
}

.table .table-filter .filter .filter-modal label:hover {
  opacity: 0.8;
  background-color: white;
}

.table .table-filter .search {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.table .table-filter .search .search-container {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border: 1px solid #80808049;
  border-radius: 0.5rem;
}

.table .table-filter .search .search-container input {
  border: none;
  outline: none;
  flex: 1;
}

.table .table-filter .search .sort {
  position: relative;
}

.table .table-filter .search .sort .sort-modal {
  padding: 0.4rem;
  background-color: whitesmoke;
  border-radius: 10px;
  width: 14rem;
  position: absolute;
  z-index: 10;
}

.table .table-filter .search .sort .sort-modal label {
  padding: 0.5rem;
  cursor: pointer;
}

.table .table-filter .search .sort .sort-modal label:hover {
  opacity: 0.8;
  background-color: white;
}

.table .table-filter .search button {
  background-color: transparent !important;
  border: none;
  color: black;
}

.table .table-filter .segment-column {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.table .table-filter .segment-column button {
  background-color: #fac710;
  color: black;
  border: none;
}

.table .table-filter .segment-column .column {
  position: relative;
}

.table .table-filter .segment-column .column button {
  background-color: white;
  color: black;
  border: 0.5px solid gray;
}

.table .table-filter .segment-column .column .column-modal {
  padding: 0.4rem;
  background-color: whitesmoke;
  border-radius: 10px;
  width: 10rem;
  position: absolute;
  z-index: 10;
}

.table .table-filter .segment-column .column .column-modal label {
  padding: 0.5rem;
}

.table .table-filter .segment-column .column .column-modal .column-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table .table-filter .segment-column .column .column-modal .column-actions button {
  padding: 0.3rem;
}

.table .table-filter .segment-column .column .column-modal .column-actions button:last-child {
  background-color: #fac710;
  color: white;
  border: none;
}

.table .table-component {
  overflow: auto;
  width: 100%;
}

.table input[type="checkbox"] {
  display: flex !important;
  width: 1.2rem;
}

.table .table-component table {
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 1px;
  text-align: left;
}

.table .table-component th {
  padding: 5px;
}

.table .table-component td {
  border-bottom: 1px solid #dededf;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
}

.table .table-component td button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: antiquewhite;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.table .table-component td button:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.table .action {
  position: relative;
}

.table .action .action-modal {
  display: flex;
  position: absolute;
  background-color: #f8f8f8;
  z-index: 10;
  border-radius: 8px;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  right: 0;
  top: calc(100% + 5px);
  transform: translateY(-50%);
}

.table .action .action-modal div {
  padding: 0.8rem 1rem;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.2s ease-in-out;
  color: #333;
  font-size: 14px;
}

.table .action .action-modal div:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.table .action .action-modal div:last-child {
  border: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table .action .action-modal div:hover {
  background-color: #f0f0f0;
  color: #000;
}
