.active-header {
  position: relative;
  padding-bottom: 0.5rem;
}
.active-header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px; /* Thickness of the line */
  background-color: #fcd95c; /* Gold color */
  border-radius: 2px;
}
.heading_master_div {
  height: 100vh;
  width: 100vw;
  border-radius: 0;
}
.navigation_bar_headers {
  padding: 1rem 1.6rem;
  display: flex;
  gap: 2rem;
  align-items: center;
}
.navigation_bar_headers > span {
  font-size: 16px;
  font-weight: 400;
}
