.right-blog {
  width: 100%;
  height: auto;
}

.right-blog .marketing-overview {
  width: 100%;
  height: 20rem;
  margin-bottom: 1rem;
  background: #fbf6e4;
  border-radius: 2rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.right-blog .marketing-overview .top-section {
  height: 60%;
  width: 100%;
  background-color: black;
  color: white;
}

.right-blog .marketing-overview .top-section h2 {
  font-weight: 500;
  padding: 1rem;
  font-size: 1.5rem;
}

.right-blog .marketing-overview .top-section .check {
  padding: 1.6rem;
  border-top: 1px solid white;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.right-blog .marketing-overview .top-section .check h3 {
  font-weight: 400;
}

.right-blog .marketing-overview .top-section .check label {
  width: 100%;
  padding: 0.4rem;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-blog .marketing-overview .top-section .check label .text-alt {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.right-blog .marketing-overview .top-section .check label .text-alt h4 {
  font-size: 0.8rem;
}

.right-blog .marketing-overview .top-section .check label .text-alt h4:first-child {
  font-size: 1.3rem;
}

.right-blog .marketing-overview .top-section .check label .text-alt h4:last-child {
  font-weight: 300;
}

.right-blog .marketing-overview .top-section .check label .progress {
  width: 100%;
  height: 1.5rem;
  border-radius: 1rem;
  background-color: white;
}

.right-blog .marketing-overview .top-section .check label .progress .bar {
  background-color: #fac710;
  border-radius: 1rem;
  height: 100%;
}

.right-blog .marketing-overview .bottom-section {
  padding: 1.6rem;
}

.right-blog .marketing-overview .bottom-section .breadcrumbs {
  display: flex;
  align-items: center;
  gap: 3px;
}

.right-blog .marketing-overview .bottom-section .breadcrumbs span {
  font-size: 1.3rem;
}

.right-blog .marketing-overview .bottom-section .marketing-menu {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.right-blog .marketing-overview .bottom-section .marketing-menu a {
  padding: 0.7rem;
  cursor: pointer;
  color: black;
  border-radius: 1rem;
  background-color: #ffffff;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.right-blog .marketing-overview .bottom-section .marketing-menu a:hover {
  transform: scale(1.1);
  background-color: #fac710;
  color: black;
  opacity: 0.6;
}

.right-blog .marketing-overview .bottom-section .marketing-menu .active {
  background-color: #fac710;
  color: black;
}
