
.email-section {
    display: flex;
    flex-direction: row;
    padding: 1em;
    justify-content: space-between;
    max-width: 1150px;
    margin: 0 auto;
}

.email-preview-section {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* gap: 6.9em */
    /* max-width: 100%; */
}

.email-preview-section .preview-title {
    font-weight: 700;
    font-size: 1.75rem;
    margin-bottom: 1em;
    margin-right: auto;
    margin-left: 7rem;
}

.preview-email .email-logo {
    width: 92px;
    height: 89px;
    border-radius: 50%;
    background: #111111;
}
.preview-inner-section {
    background-color: #0E0E0E;
   display: grid;
   place-content: center;
   margin: 1rem;
   
}

.email-image-wrapper {
    text-align: center;
    margin: 2em;
}

.preview-email {
    width: 500px;
    max-width: 500px;
    /* min-width: 60%; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.email-btns {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    
}

.email-btns a {
    width: 402px;
    border-radius: 8px;
    padding: 1em;
    text-decoration: none;
    text-align: center;
}

.email-btns :first-child {
    background-color: #111111;
    color: #FFFFFF;
}

.email-btns a:nth-child(2) {
    border: 1px solid #FAC710;
    background-color: #FFFFFF;
    color: #111111;
}


/* Create Email Form Section */
.email-form-section {
    display: flex;
    flex-direction: column;
    margin-top: 3.5rem;
    gap: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 1em;
    box-sizing: border-box;
    height: 100%;
}
.email-form-input {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.email-form-input label{
    max-width: 100%;
    background-color: #FBF6E4; 
    padding: 1em;
}

.email-form-input input, 
.email-form-input textarea {
    width: 451px;
    height: 100px;
    padding: .5rem 1rem;
    font-size: 1rem;
}

/* Add Keywords Section  */

.keyword-tags-section .tag-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 20px;
    box-sizing: border-box;
}
.tag-wrapper .keyword-tag {
    background-color: #FBF6E4;
    width: content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 16px;
    height: 48px;
    padding: 0 1em;
    border: 2px solid #838383;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    font-weight: 500;
}

.tag-wrapper .tag-close-btn {
    border: none;
    background: none;
    cursor: pointer;
}




.dynamic-card-dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 5px;
    width: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .dynamic-card-dropdown-list {
    list-style: none;
    margin: 0;
    padding: 10px;
  }
  
  .dynamic-card-dropdown-item {
    padding: 8px 0;
    cursor: pointer;
  }
  
  .dropdown-separator {
    padding-left: 10px;
    margin-left: 10px;
    border-left: 2px solid;
  }