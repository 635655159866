.email-overview {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 100%;
  border-radius: 1rem;
}

.email-overview .top-section {
  padding: 1.6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: #fbf6e4;
}

.email-overview .top-section span {
  cursor: pointer;
  padding: 0.4rem;
  border-radius: 0.8rem;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.email-overview .top-section span:hover {
  opacity: 0.7;
  border: 1px solid black;
}

.email-overview .top-section .active {
  border-radius: 0.8rem;
  border: 1px solid black;
}
