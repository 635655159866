.dynamic-card {
  display: flex;
  flex-direction: column;
  margin: 1rem 3rem;
  background-color: #fbf6e4;
  height: 13rem;
  justify-content: center;
  padding: 0 1rem;
  border: 2px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.dynamic-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.dynamic-card-content {
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: center;
}

.dynamic-card-title {
  font-weight: 600;
  font-size: 27px;
  font-family: Lato;
}

.dynamic-card-dropdown {
  position: relative;
  display: inline-block;
}

.dynamic-card-button {
  border: 1px solid #ccc;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: 5px;
  font-size: 16px;
}

.dynamic-card-dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  width: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dynamic-card-dropdown-list {
  list-style: none;
  margin: 0;
  padding: 10px;
}

.dynamic-card-dropdown-item {
  padding: 8px 0;
  cursor: pointer;
}

.dropdown-separator {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 2px solid;
}
