/* Container styles */
.new_publish_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  padding: 2rem;
  flex-direction: column;
}

.first_heading_h2 {
  padding-top: 2rem;
  text-align: center;
}
.step_one_first_heading_h6 {
  text-align: start;
  padding: 2rem;
  font-size: 14px;
}

.email_selection_section_div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step_greater_one {
  display: flex;
  align-items: flex-start;
  padding: 2rem 5rem;
  justify-content: flex start;
}

.step_last_div {
  display: flex;
  width: 100%;
  gap: 3rem;
  align-items: center;
  justify-content: space-between;
}

.step_last_div {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1rem;
}

/* Top section styles */
.new_publish_top-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.new_publish_heading {
  font-weight: 500;
  font-size: 35px;
}

/* Button container */
.new_publish_button-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

/* Common button styles */
.new_publish_button {
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  width: 140px;
  max-width: 180px;
  background-color: #fff;
}

.new_publish_button:hover {
  background-color: #fac710;
  border: 1px solid #fac710;
}
/* Black button styles */
.new_publish_button-black {
  background-color: black;
  border: 1px solid black;
  color: white;
}

.new_publish_button-black:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

/* Transparent button styles */
.new_publish_button-transparent {
  background-color: transparent;
  border: 1px solid black;
  color: black;
}

.new_publish_button-transparent:hover {
  background-color: black;
  color: white;
}

/* Time Select Dropdown */
.new_publish_time_select {
  font-size: 18px;
  padding: 0.5rem;
  appearance: none; /* This removes the default dropdown arrow for full control */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.new_publish_time_select:focus {
  outline: none;
  border-color: #55b49d; /* Highlight border on focus */
}

/* Ensures the dropdown opens downwards */
.new_publish_time_select::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #555; /* Adds a custom arrow for the dropdown */
  transform: translateY(-50%);
}

.new_publish_new_div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 4rem;
  flex-direction: column;
}

.cancel_button > button {
  width: 9rem;
  height: 2.5rem;
  margin-top: 1rem;
  background-color: #fac710;
  border: 1px solid #fac710;
  border-radius: 4px;
  cursor: pointer;
}

.new_publish_new_subdiv {
  display: flex;
  background-color: #fbf6e4;
  width: 80%;
  height: 700px;
  flex-direction: column;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

.new_publish_step_master {
  background-color: white;
  height: 520px;
  width: 800px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.new_publish_campaign_div {
  display: flex;
  align-items: center;
  margin-top: 3rem;
  justify-content: center;
}

.new_publish_campaign_div > input {
  width: 641px;
  height: 97px;
  border-radius: 2px;
  padding: 8px;
  font-size: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 5rem;
}

.new_publish_date_master {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;
  margin-bottom: 1rem;
}
.new_publish_time_picker {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.new_publish_time_subdiv {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.new_publish_time_subdiv_select {
  font-size: 18px;
  padding: 0.5rem;
}

.new_publish_dynamic_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
}

.new_publish_dynamic_card > h5 {
  font-size: 30px;
}

.new_publish_card_button {
  height: 45px;
  width: 160px;
  margin-top: -2rem;
  background-color: #fac710;
  border: 1px solid #fac710;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.new_publish_card_button:disabled {
  background-color: gray; /* Background color when disabled */
  cursor: not-allowed; /* Change cursor to show it's disabled */
  opacity: 0.6; /* Add some transparency to indicate the disabled state */
  border: none;
}

.new_publish_card_button_back {
  height: 45px;
  width: 160px;
  margin-top: -2rem;
  background-color: black;
  border: 1px solid black;
  outline: none;
  border-radius: 3px;
  cursor: pointer;
  color: white;
}

.new_publish_email_list {
  width: 90%;
  display: flex;
  flex-direction: column;
  max-height: 200px; /* Set a max height for the list */
  overflow-y: auto; /* Enable scrolling */
  padding: 1rem;
  border: 1px solid #ccc; /* Optional: Border around the list */
  border-radius: 4px;
  margin-bottom: 1rem;
}

/* For WebKit browsers (Chrome, Safari) */
.new_publish_email_list::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.new_publish_email_list::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar color */
  border-radius: 4px; /* Optional: Add rounded corners to the scrollbar */
}

.new_publish_email_list::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

.new_publish_email_button {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc; /* Outline the button */
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  text-align: center;
}

.new_publish_email_button_selected {
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border: 2px solid #55b49d; /* Highlighted border for selected email */
  border-radius: 4px;
  background-color: #f0f9f7;
  cursor: pointer;
  text-align: center;
}

.new_publish_email_list_container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-direction: column;
  height: 100%; /* Full height to center vertically */
}

.loading-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(
    255,
    255,
    255,
    0.7
  ); /* Optional semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
}

.spinner {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.success-message {
  color: green;
  margin-top: 10px;
  font-weight: bold;
}
