.main-container {
    width: 100%;
    height: 100vh;
    padding: 0 36px;
    overflow-x: hidden;
  }
  
  .xcelsz-logo {
    border-radius: 50px;
  }
  
  .work-space {
    display: flex;
  }
  
  .welcome-container {
    background-color: #fbf6e4;
    padding: 3rem;
  }
  
  .welcome-container h2 {
    font-size: 2.5rem;
  }
  
  .welcome-container h3 {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }
  
  .welcome-container span {
    color: #fac710;
  }
  
  .date {
    color: #bcbcbc;
    margin: 1rem 0;
    font-size: 1.5rem;
  }
  
  
  
  .dropdown-ihubx {
    position: relative;
  }
  
  .dropdown {
    border-radius: 12px;
    position: absolute;
    right: 0;
    background-color: #fff;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    width: 200px;
  }
  
  .dropdown-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 1rem;
      border-radius: 12px;
     
  }
  .dropdown-item-home{
    width: 250px;
    padding: 0rem 1rem;
    height: 40px;
  }
  
  .dropdown-item:hover {
      background-color: #fac710;
  }
  
  .menu:hover{
    background: #fac710;
  }